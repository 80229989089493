<template>
    <component
        :is="item.uri.includes(location.origin) ? Link : 'a'"
        :target="isExternal ? '_blank': '_self'"
        :rel="isExternal ? 'noopener noreferrer': ''"
        :href="item.uri"
        class="aa-ItemLink p-2 md:p-4 rounded bg-gray-50 group hover:bg-deep-teal-100 focus:bg-deep-teal-100"
    >
        <div class="aa-ItemContent">
            <div
                class="aa-ItemIcon group-hover:bg-deep-teal-700 group-focus:bg-deep-teal-700 p-1 md:mr-2 !shadow-none"
            >
                <component
                    :is="icon"
                    aria-hidden="true"
                    class="w-5 h-5 text-deep-teal-700 group-hover:text-white group-focus:text-white [&>path]:stroke-[2]"
                />
            </div>

            <div class="aa-ItemContentBody">
                <div class="aa-ItemContentTitle font-normal">
                    <component
                        :is="components.Highlight"
                        :hit="item"
                        :attribute="titleAttribute"
                    />
                </div>
                <div class="aa-ItemContentDescription text-gray-800 font-normal leading-snug line-clamp-3">
                    <component
                        :is="components.Highlight"
                        v-if="item[descriptionAttribute]"
                        :hit="item"
                        :attribute="descriptionAttribute"
                    />
                </div>
            </div>
        </div>

        <ChevronRightIcon
            aria-hidden="true"
            class="aa-ItemActionIcon size-5 !text-gray-800 group-hover:!text-deep-teal-700 group-focus:!text-deep-teal-700"
        />
    </component>
</template>

<script setup>
import { DocumentTextIcon } from '@heroicons/vue/24/outline';
import { ChevronRightIcon } from '@heroicons/vue/20/solid';
import { Link } from '@inertiajs/vue3';
import { useBrowserLocation } from '@vueuse/core';

/** @type {{ item: any, components: import('@algolia/autocomplete-shared/dist/esm/js/AutocompleteComponents').AutocompleteComponents }} */
const props = defineProps({
    item: {
        type: Object,
        default() {},
    },
    components: {
        type: Object,
        default() {},
    },
    icon: {
        type: Function,
        default: DocumentTextIcon,
    },
    titleAttribute: {
        type: String,
        default: 'title',
    },
    descriptionAttribute: {
        type: String,
        default: 'description',
    },
});

const location = useBrowserLocation();
const isExternal = !props.item.uri.includes(location.origin);
</script>

<style>
.aa-ItemContentTitle,
.aa-ItemContentDescription,
.aa-ItemContentTitle mark,
.aa-ItemContentDescription mark {
    @apply font-normal;
}

.aa-ItemContent mark,
.aa-ItemContentDescription mark {
    @apply text-sunset-orange-500 bg-transparent;
}

.aa-Item[aria-selected="true"] .aa-ItemLink {
    @apply bg-deep-teal-100;
}

.aa-Item[aria-selected="true"] .aa-ItemIcon {
    @apply bg-deep-teal-700;
}

.aa-Item[aria-selected="true"] .aa-ItemActionIcon {
    @apply !text-deep-teal-700;
}

.aa-Item[aria-selected="true"] .aa-ItemIcon > svg {
    @apply text-white;
}
</style>
